import axios from 'axios';
import config from '../config';


// production api_url
const API_URL = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000/api/';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error)
  }
);

api.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Company Methods
export const getCompanies = async (authToken) => {
  const response = await api.get(`${API_URL}companies/`, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};
export const getCompany = async (authToken, id) => {
  const response = await api.get(`${API_URL}companies/${id}/`, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};

// Leads Methods
export const getLeadSources = async (authToken) => {
  const response = await api.get(`${API_URL}lead-sources/`, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};

export const getLeadStatusCounts = async () => {
  const response = await api.get(`${API_URL}leads/status_counts/`);
  return response.data;
};

export const getLeadsBySource = async () => {
  const response = await api.get(`${API_URL}leads/source_counts/`);
  return response.data; // Returns [{name: "website", value: 5}, ...]
};

export const getLeadsCount = async (status = '') => {
  const queryParams = new URLSearchParams({
    ...(status && { status }) // Only add status if it exists
  });
  const response = await api.get(`${API_URL}leads/?${queryParams.toString()}`);
  return response.data.count; // DRF pagination includes total count
};

export const getLeads = async (
  status = '',
  source = '',
  sortOrder = '-created_at',
  page = 1,
  pageSize = 10) => {
    const queryParams = new URLSearchParams({
      status,
      source,
      ordering: sortOrder,
      page,
      page_size: pageSize
    });

    const response = await api.get(`${API_URL}leads/?${queryParams.toString()}&page_size=${pageSize}`);
    return response.data;
  };

export const getLead = async (authToken, id) => {
  console.log("LeadData:", authToken);
  console.log("LeadData:", id);
  const response = await api.get(`${API_URL}leads/${id}/`);
  console.log("response:", response);
  return response.data;
};

export const getInfo = async () => {
  const response = await api.get(`${API_URL}info/`);
  console.log("response:", response);
  return response.data;
};

export const createLead = async (authToken, leadData) => {
  console.log("LeadData:", leadData);
  console.log("LeadData:", authToken);
  const response = await api.post(`${API_URL}leads/`, leadData);
  console.log("response:", response);
  return response.data;
};

export const editLead = async (authToken, leadId, leadData) => {
  console.log("api_url:", API_URL);
  console.log("LeadData:", leadData);
  console.log("LeadId:", leadId);
  const response = await api.put(`${API_URL}leads/${leadId}/`, leadData);
  return response.data;
}

export const updateLead = async (authToken, leadId, leadData) => {
  console.log("api_url:", API_URL);
  console.log("LeadData:", leadData);
  console.log("LeadId:", leadId);
  const response = await api.patch(`${API_URL}leads/${leadId}/`, leadData);
  return response.data;
}

export const deleteLead = async (authToken, leadId) => {
  try {
    const response = await api.delete(`${API_URL}leads/${leadId}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Patient Methods
export const getPatients = async (authToken) => {
  const response = await api.get(`${API_URL}patients/`, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};

export const getPatient = async (authToken, id) => {
  console.log("PatientData:", authToken);
  console.log("PatientData:", id);
  const response = await api.get(`${API_URL}patients/${id}/`, {
    headers: { Authorization: `Token ${authToken}` }
  });
  console.log("response:", response);
  return response.data;
};

export const createPatient = async (authToken, patientData) => {
  console.log("PatientData:", patientData);
  console.log("PatientData:", authToken);
  const response = await api.post(`${API_URL}patients/`, patientData, {
    headers: { Authorization: `Token ${authToken}` }
  });
  console.log("response:", response);
  return response.data;
};

export const editPatient = async (patientData, id, authToken) => {
  const response = await api.put(`${API_URL}patients/${id}/`, patientData, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};

// Opportunity methods
export const getOpportunities= async (authToken) => {
  const response = await api.get(`${API_URL}opportunities/`, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};

// Location methods
export const getLocations = async () => {
  const response = await api.get(`${API_URL}locations/`);
  console.log('Locations API response:', response.data);
  console.log('Full API response:', response);
    console.log('Response data type:', typeof response.data);
    console.log('Response data:', response.data);
  return response.data.results;
};

// Account methods
export const getAccounts= async (authToken) => {
  const response = await api.get(`${API_URL}accounts/`);
  return response.data;
};
// Lead tasks
export const getLeadTasks = async (id) => {
  if (!id) {
    throw new Error('Lead ID is required');
  }
  try {
    const response = await api.get(`${API_URL}leads/${id}/tasks/`);
    return response.data.results;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const getPatientTasks = async (authToken, id) => {
  if (!id) {
    throw new Error('Patient ID is required');
  }
  try {
    const response = await api.get(`${API_URL}patients/${id}/tasks/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const getLeadNotes = async (id) => {
  if (!id) {
    throw new Error('Lead ID is required');
  }
  try {
    const response = await api.get(`${API_URL}leads/${id}/notes/`);
    return response.data.results;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const getPatientNotes = async (authToken, id) => {
  if (!id) {
    throw new Error('Patient ID is required');
  }
  try {
    const response = await api.get(`${API_URL}patients/${id}/notes/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const getNote = async (noteId) => {
  console.log("noteId: ", noteId)
  if (!noteId) {
    throw new Error('Note ID is required');
  }
  try {
    const response = await api.get(`${API_URL}notes/${noteId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};

export const createNote = async (authToken, contentType, patientId, noteData) => {
  console.log("authToken:", authToken);
  console.log("patientId:", patientId);
  console.log("noteData:", noteData);
  let endpoint;
  endpoint = `${API_URL}${contentType}/${patientId}/notes/`;
  try {
    const response = await api.post(endpoint, noteData, {
      headers: { 
        Authorization: `Token ${authToken}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating note:', error);
    throw error;
  }
};

export const updateNote = async (authToken, noteId, updatedData) => {
  if (!noteId) {
    throw new Error('Note ID is required');
  }
  try {
    const response = await api.put(`${API_URL}notes/${noteId}/`, updatedData, {
      headers: { 
        Authorization: `Token ${authToken}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating note:', error);
    throw error;
  }
};

export const deleteNote = async (authToken, noteId) => {
  if (!noteId) {
    throw new Error('Note ID is required');
  }
  try {
    await api.delete(`${API_URL}notes/${noteId}/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    // If successful, this will not return any data
    return true;
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

export const getTasks = async (authToken, patientId) => {
  if (!patientId) {
    throw new Error('Patient ID is required');
  }
  try {
    const response = await api.get(`${API_URL}patients/${patientId}/tasks/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};

export const getTask = async (authToken, taskId) => {
  console.log("authToken: ", authToken)
  console.log("taskId: ", taskId)
  if (!taskId) {
    throw new Error('task ID is required');
  }
  try {
    const response = await api.get(`${API_URL}tasks/${taskId}/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};

export const updateTask = async (authToken, taskId, updatedData) => {
  if (!taskId) {
    throw new Error('Task ID is required');
  }
  try {
    const response = await api.put(`${API_URL}tasks/${taskId}/`, updatedData, {
      headers: { 
        Authorization: `Token ${authToken}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};

export const deleteTask = async (authToken, taskId) => {
  if (!taskId) {
    throw new Error('Task ID is required');
  }
  try {
    await api.delete(`${API_URL}tasks/${taskId}/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    // If successful, this will not return any data
    return true;
  } catch (error) {
    console.error('Error deleting task:', error);
    throw error;
  }
};




export const createTask = async (authToken, contentType, id, taskData) => {
  console.log('Task data being sent:', taskData);
  let endpoint;
  endpoint = `${API_URL}${contentType}/${id}/tasks/`;


  if (!id) {
    throw new Error('ID is required');
  }
  try {
    const response = await api.post(endpoint, taskData, {
      headers: { 
        Authorization: `Token ${authToken}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
    }
    throw error;
  }
};

export const getStaffList = async (authToken) => {
  try {
    const response = await api.get(`${API_URL}available-staff/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching staff list:', error);
    throw error;
  }
};

export const getUserNotes = async (authToken) => {
  try {
    const response = await api.get(`${API_URL}notes/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user notes:', error);
    throw error;
  }
};

export const getUserTasks = async (authToken) => {
  try {
    const response = await api.get(`${API_URL}tasks/my_tasks/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user tasks:', error);
    throw error;
  }
};

export const getDocuments = async (authToken, patientId) => {
  try {
    const response = await api.get(`${API_URL}documents/?patient=${patientId}`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user documents:', error);
    throw error;
  }
};

export const getServices = async (authToken, companyId) => {
  if (!companyId) {
    throw new Error('Company ID is required');
  }
  try {
    const response = await api.get(`${API_URL}companies/${companyId}/services/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    throw error;
  }
};
export const getService = async (authToken, companyId, serviceId) => {
  if (!companyId) {
    throw new Error('Company ID is required');
  }
  try {
    const response = await api.get(`${API_URL}companies/${companyId}/services/${serviceId}/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    throw error;
  }
};
export const updateService = async (authToken, companyId, serviceId, updatedData) => {
  if (!companyId) {
    throw new Error('Company ID is required');
  }
  try {
    const response = await api.put(`${API_URL}companies/${companyId}/services/${serviceId}/`, updatedData, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    throw error;
  }
};
export const createService = async (authToken, companyId, serviceData) => {
  const response = await api.post(`${API_URL}companies/${companyId}/services/`, serviceData, {
    headers: { Authorization: `Token ${authToken}` }
  });
  return response.data;
};

// Appointments
export const getAppointments = async (authToken, patientId) => {
  if (!patientId) {
    throw new Error('Patient ID is required');
  }
  try {
    const response = await api.get(`${API_URL}patients/${patientId}/appointments/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
};
export const getAppointment = async (authToken, patientId, appointmentId) => {
  if (!appointmentId) {
    throw new Error('Appointment ID is required');
  }
  try {
    const response = await api.get(`${API_URL}patients/${patientId}/appointments/${appointmentId}/`, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
};
export const createAppointment = async (authToken, patientId, appointmentData) => {
  try {
    const response = await api.post(`${API_URL}patients/${patientId}/appointments/`, appointmentData, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error){
    console.error("error: ", error)
  }
};
export const updateAppointment = async (authToken, patientId, id, data) => {
  if (!patientId) {
    throw new Error('Patient ID is required');
  }
  try {
    const response = await api.put(`${API_URL}patients/${patientId}/appointments/${id}/`, data, {
      headers: { Authorization: `Token ${authToken}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    throw error;
  }
};

export const getStaffForPatient = async (authToken, patientId) => {
  const response = await api.get(`${API_URL}patients/${patientId}/available-staff/`, {
    headers: { 'Authorization': `Token ${authToken}`, }
  });
  console.log(response)
  return response.data;
};

export const getStaffForLead = async (authToken, leadId) => {
  const response = await api.get(`${API_URL}leads/${leadId}/available-staff/`, {
    headers: { 'Authorization': `Token ${authToken}`, }
  });
  console.log(response)
  return response.data;
};

export const getObjectHistory = async (authToken, objectType, objectId) => {
  const response = await api.get(`${API_URL}${objectType}s/${objectId}/history/`, {
    headers: { 'Authorization': `Token ${authToken}`, }
  });
  console.log(response)
  return response.data;
};