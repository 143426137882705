// src/components/TasksList.js
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { getLeadTasks, getPatientTasks } from "../services/api.js";
import TaskCreate from "./TaskCreate";

const TasksList = ({ authToken, id: propId}) => {
  const { id: urlId } = useParams();
  const location = useLocation();

  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState("due_date");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [showTaskCreate, setShowTaskCreate] = useState(false);

  const effectiveId = propId || urlId;
  const isLead = location.pathname.includes('/leads/');
  const contentType = isLead ? 'leads' : 'patients'

  const fields = [
    { key: "title", label: "Title" },
    { key: "description", label: "Description" },
    { key: "due_date", label: "Due Date" },
    { key: "status", label: "Status" },
    { key: "assigned_to", label: "Assigned To" },
  ];

  const fetchTasks = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const getTasks = isLead ? getLeadTasks : getPatientTasks;
      const fetchedTasks = await getLeadTasks(effectiveId);
      console.error("is lead?", isLead)
      setTasks(fetchedTasks);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setError("Failed to fetch tasks. Please try again later.");
      setLoading(false);
    }
  }, [effectiveId, isLead]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedTasks = [...tasks].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const filteredTasks = sortedTasks.filter((task) =>
    fields.some((field) => {
      const value = task[field.key];
      return (
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const handleTaskCreated = () => {
    fetchTasks();
    setShowTaskCreate(false);
  };

  if (loading) {
    return <div className="text-center py-8">Loading tasks...</div>;
  }

  return (
    <div className="w-full px-2 sm:px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">
          Tasks
        </h2>
        <div className="flex flex-col sm:flex-row items-center">
          <input
            type="text"
            placeholder="Search tasks..."
            className="border rounded py-2 px-3 mb-2 sm:mb-0 sm:mr-2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            onClick={() => setShowTaskCreate(!showTaskCreate)}
            className="bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded transition duration-300"
          >
            {showTaskCreate ? "Cancel" : "Create New Task"}
          </button>
        </div>
      </div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
          <button
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => {
              setError(null);
              fetchTasks();
            }}
          >
            <span className="text-lg">&times;</span>
          </button>
        </div>
      )}
      {showTaskCreate && (
        <TaskCreate
          authToken={authToken}
          contentType={contentType}
          patientId={effectiveId}
          onTaskCreated={handleTaskCreated}
        />
      )}
      <div className="bg-white shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(field.key)}
                >
                  {field.label}
                  {sortField === field.key && (
                    <span className="ml-1">
                      {sortDirection === "asc" ? "↑" : "↓"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredTasks.length > 0 ? (
              filteredTasks.map((task) => (
                <tr key={task.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-normal">
                    <Link
                      to={`/${contentType}/${effectiveId}/tasks/${task.id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      {task.title}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">{task.description}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {formatDate(task.due_date)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">{task.status}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">{task.assigned_to}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={fields.length}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                >
                  No tasks found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TasksList;