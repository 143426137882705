import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import {getInfo} from '../services/api'

const Info = ({}) => {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await getInfo();
        setInfo(response);
      } catch (error) {
        console.error('Error fetching info:', error);
        setError(error.response?.data || 'An error occurred');
      }
    };
    fetchInfo();
  }, []);

  if (error) {
    return <div className="text-red-500">Error: {JSON.stringify(error)}</div>;
  }

  if (!info) {
    return <div>Loading...</div>;
  }


return (
      <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">{info.company}</h1>
      </div>
      
      <div className="bg-white shadow-sm border border-gray-200 rounded-md overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-gray-50">
          <h2 className="text-lg font-medium text-gray-900">User Information</h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Username</dt>
              <dd className="mt-1 text-sm text-gray-900">{info.user_name}</dd>
            </div>
            {(info.first_name || info.last_name) && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{`${info.first_name} ${info.last_name}`.trim()}</dd>
              </div>
            )}
          </dl>
        </div>
      </div>

      <div className="bg-white shadow-sm border border-gray-200 rounded-md overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-gray-50">
          <h2 className="text-lg font-medium text-gray-900">Locations</h2>
        </div>
        <div className="border-t border-gray-200">
          {info.locations && info.locations.map((location, index) => (
            <div key={location.form_id} className={`px-4 py-5 sm:p-6 ${index !== 0 ? 'border-t border-gray-200' : ''}`}>
              <h3 className="text-lg font-medium text-gray-900 mb-2">{location.name}</h3>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900">{location.address}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900">{location.phone}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900">{location.email}</dd>
                </div>
                {location.notification_email && (
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Notification Email</dt>
                    <dd className="mt-1 text-sm text-gray-900">{location.notification_email}</dd>
                  </div>
                )}
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">Form ID</dt>
                  <dd className="mt-1 text-sm text-gray-900">{location.form_id}</dd>
                </div>
              </dl>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Info;