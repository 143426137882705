import React, { useState, useEffect } from "react";
import { getLeads, getLeadsCount, getLeadStatusCounts, getLeadsBySource } from "../services/api";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Link } from "react-router-dom";
import MyTasks from "./MyTasks";
import { ChartPieIcon, UserGroupIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';
import { formatLeadSource } from '../utils/displayFormatters';
import { formatLeadStatus, LEAD_STATUSES } from '../utils/displayFormatters';

const Homepage = ({ authToken }) => {
  const [leadsByStatus, setLeadsByStatus] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [newLeadsCount, setNewLeadsCount] = useState([]);
  const [totalLeadsCount, setTotalLeadsCount] = useState([]);
  const [leadConversionRate, setLeadConversionRate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leadStatusData, setLeadStatusData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);

  const transformLeadStatusData = (data) => {
    return data.map((item) => ({
      ...item,
      name: formatLeadStatus(item.name),
    }));
  };


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Get status counts first since it's critical for the pie chart
        const statusCounts = await getLeadStatusCounts();
        setLeadStatusData(transformLeadStatusData(statusCounts));

        // Get source counts first since it's critical for the pie chart
        const sourceCounts = await getLeadsBySource();
        setLeadSourceData(sourceCounts);

        // Get other data in parallel
        const [leadsResponse, newCount, totalCount] = await Promise.all([
          getLeads(),
          getLeadsCount('new'),
          getLeadsCount()
        ]);

        const leads = leadsResponse.results;
        
        // Calculate conversion rate using statusCounts instead of paginated leads
        const convertedCount = statusCounts.find(s => s.name === 'converted')?.value || 0;
        const calculatedTotal = statusCounts.reduce((sum, {value}) => sum + value, 0); // renamed from totalCount
        const conversionRate = calculatedTotal ? (convertedCount / calculatedTotal) * 100 : 0;

        setAllLeads(leads);
        setNewLeadsCount(newCount);
        setTotalLeadsCount(totalCount);
        setLeadConversionRate(conversionRate.toFixed(2));

      } catch (err) {
        setError(err.message);
        console.error('Dashboard data fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];
  const COLORS = ["#3B82F6", "#10B981", "#F59E0B", "#EF4444", "#8B5CF6"];
  const statuses = ["new", "contacted", "qualified", "lost", "converted"];
  if (loading)
    return <div className="text-center py-8">Loading dashboard...</div>;
  if (error)
    return <div className="text-center py-8 text-red-600">{error}</div>;

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Dental Practice Dashboard</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Lead Conversion Rate */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center justify-center h-[50px]">
              <ArrowTrendingUpIcon className="h-6 w-6 text-green-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Conversion Rate</h2>
            </div>
            <div className="flex items-center justify-center h-32">
              <div className="text-5xl font-bold text-green-600">{leadConversionRate}%</div>
            </div>
          </div>

          {/* Total Leads */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center justify-center h-[50px]">
              <UserGroupIcon className="h-6 w-6 text-indigo-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">New Leads</h2>
            </div>
            <div className="flex items-center justify-center h-32">
            <Link 
              to="/leads?status=new"
              className="text-5xl font-bold text-indigo-600 hover:text-indigo-800 transition-colors cursor-pointer"
            >
              {newLeadsCount}
            </Link>
            </div>
          </div>

          {/* Leads by Status */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center mb-4">
              <ChartPieIcon className="h-6 w-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Leads by Status</h2>
            </div>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart>
                <Pie
                  data={leadStatusData}
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={70}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {leadStatusData.map((entry, index) => {
                    const statusObj = LEAD_STATUSES[index];
                    const color = statusObj?.color?.replace('bg-', '') || COLORS[index % COLORS.length];
                    return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  })}
                </Pie>
                <Tooltip formatter={(value, name) => [value, name]} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          {/* Leads by Source */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center mb-4">
              <ChartPieIcon className="h-6 w-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Leads by Source</h2>
            </div>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={leadSourceData}
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={70}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${formatLeadSource(name)} ${(percent * 100).toFixed(0)}%`}
                >
                  {leadSourceData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Homepage;
