import React from "react";
import { LEAD_STATUSES } from '../utils/displayFormatters';

const LeadStatusFlow = ({ currentStatus = "New", onStatusChange }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-2">
      {LEAD_STATUSES.map((status, index) => (
        <React.Fragment key={status.key}>
          <button
            onClick={() => onStatusChange?.(status.key)}
            className={`px-3 py-0 rounded-full border transition-all
                ${
                  currentStatus.toLowerCase() === status.key
                    ? `${status.color} text-white border-amber-400 font-medium`
                    : "bg-white border-gray-300 hover:border-gray-400"
                }`}
          >
            {status.label}
          </button>
        </React.Fragment>
      ))}
    </div>
  );
};

export default LeadStatusFlow;
