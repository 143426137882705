import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import config from '../config';
// import { login } from '../services/auth';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await axios.post(
      `${config.BASE_API_ENDPOINT}/api/token/`, 
      { username, password },
      { 
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
    
    console.log('Login response:', response.data);
    
    // Explicitly store tokens in localStorage as a fallback
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
    
    onLogin(response.data);
    navigate('/home');
  } catch (error) {
    console.error('Login error:', error);
    console.log('Error response:', error.response);
  }
};

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-4 shadow-md rounded-md bg-white">
      <h2 className="text-xl font-bold mb-4">Login</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4">
        <label className="block text-gray-700">Username</label>
        <input
          type="string"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <button type="submit" className="w-full bg-accent text-white p-2 rounded-md hover:bg-accent-dark">
        Login
      </button>
    </form>
  );
};

export default Login;